import React, { useState, useEffect } from 'react';
import LoginComponent from './LoginComponent';
import AlertComponent from '../../../commons/popup/AlertComponent';

import {
  setAccessToken,
  setRefreshToken,
  getLoginId,
  setLoginId,
  getAccessToken,
  removeAuthTokens
} from '../../../../helpers/authHelper'
import {checkTerms7, login} from '../../../../service/auth/Auth.service';
import { getMemberInfo } from '../../../../service/member/Member.service'
import { setIsLogin, setAuthUserInfo } from '../../../../store/common/auth';
import { useSelector, useDispatch } from 'react-redux';
import { sha256 } from 'js-sha256';
import TermsComponent from "./TermsComponent";
import SleeperAccountComponent from "../../../commons/popup/SleeperAccountComponent";
import queryString from "query-string";
import {isEmptyObject} from "../../../../helpers/objectHelper";
import {getMobileAppHeaderData} from "../../../../helpers/apiHelper";
import {getTeamCodeByDomain} from "../../../../helpers/commonHelper";
import {setCookie} from "../../../../helpers/cookieHelper";


const LoginContainer = props => {
  const { history, location } = props;
  const { isLogin } = useSelector(state => state.commonAuth);
  const [sleeperId, setSleeperIdId] = useState(null);
  const [id, setId] = useState(null);
  const [pwd, setPwd] = useState(null);
  const [isSaveLoginId, setIsSaveLoginId] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [ terms, setTerms ] = useState(null);
  const [isSleeperDisplay, setIsSleeperDisplay] = useState(false);
  const teamCode = getTeamCodeByDomain();
  const dispatch = useDispatch();

  const alertPopupClose = e => {
    e.preventDefault();
    setIsAlertPopupDisplay(false);
    setAlertMessage('');
  }

  const openAlertPopup = message => {
    setAlertMessage(message);
    setIsAlertPopupDisplay(true);
  }

  const clickLoginButton = async e => {
    // e.preventDefault();
    if (id && pwd) {
      if (isProcessing) {
        return;
      }

      let param = { id: id, pwd: sha256(pwd) };
      setIsProcessing(true);
      let result = await checkTerms7(param);
      setIsProcessing(false);
      // let result = { status: 200}
      if (result.status === 200) {
        if (result.data && result.data.length > 0) {
          setTerms(result.data[0]);

        } else {
          setIsProcessing(true);
          result = await login(param);
          setIsProcessing(false);

          if (result.status === 200) { // 로그인 성공
            setAccessToken(result.data.accessToken);
            setRefreshToken(result.data.refreshToken);
            if (isSaveLoginId) {
              setLoginId(id);
            }

            const memberInfo = await getMemberInfo();
            if (memberInfo) {
              dispatch(setAuthUserInfo(memberInfo));
              // window.logSetUserId(memberInfo.id);
              setCookie("UID", memberInfo.id)
              if(memberInfo.sleepDttm) {
                setIsSleeperDisplay(true); // 휴면 인증
                removeAuthTokens();
                dispatch(setIsLogin(false));
                return;
              }
            }
            dispatch(setIsLogin(true));

            if (window.location.pathname === '/auth/login') {
              // 로그인 성공 후 location.state.from이 있으면 해당 url로 push, 그렇지 않으면 메인 화면 렌더링
              if(teamCode === 'YH') {
                history.push('/');
              } else {
                if (location.state && location.state.from) {
                  history.push(location.state.from);
                } else {
                  history.push('/');
                }
              }
            }

          } else {
            openAlertPopup(result.data.message);
          }
        }

      } else {
        openAlertPopup(result.data.message);
        return;
      }

    } else {
      openAlertPopup('아이디 비밀번호를 입력해 주세요.')
    }
  }

  const clickTermsLoginButton = async ( termsType, agree ) => {

    if (id && pwd) {
      if (isProcessing) {
        return;
      }

      if (!agree) {
        openAlertPopup('필수 약관에 동의해주세요.')
        return;
      }

      setIsProcessing(true);
      let param = { id: id, pwd: sha256(pwd), termsType: termsType, agree: agree };
      let result = await login(param);
      setIsProcessing(false)

      if (result.status === 200) {
        setAccessToken(result.data.accessToken);
        setRefreshToken(result.data.refreshToken);

        setIsProcessing(true);
        const memberInfo = await getMemberInfo();
        setIsProcessing(false)

        if (isSaveLoginId) {
          setLoginId(id);
        }

        if (memberInfo) {
          dispatch(setAuthUserInfo(memberInfo));
          // window.logSetUserId(memberInfo.id);
          setCookie("UID", memberInfo.id);
          if(memberInfo.sleepDttm) {
            setIsSleeperDisplay(true);  // 휴면 인증
            removeAuthTokens();
            dispatch(setIsLogin(false));
            return;
          }
        }
        dispatch(setIsLogin(true));

        // 로그인 성공 후 location.state.from이 있으면 해당 url로 push, 그렇지 않으면 메인 화면 렌더링

        if(teamCode === 'YH') {
          history.push('/');
        } else {
          if (location.state && location.state.from) {
            history.push(location.state.from);
          } else {
            history.push('/');
          }
        }
      } else {
        openAlertPopup(result.data.message);
      }

    } else {
      openAlertPopup('아이디 비밀번호를 입력해 주세요.')
    }
  }

  const changeIsSaveLoginId = e => {
    setIsSaveLoginId(e.currentTarget.checked)
  }

  const changeIdValue = e => {
    setId(e.currentTarget.value);
  }
  const changePwdValue = e => {
    setPwd(e.currentTarget.value);
  }

  const clickOkButton = e => {
    setIsSleeperDisplay(false);

    const mobileAppHeaerValues = getMobileAppHeaderData();
    // app 인 경우 웹뷰 종료 callback 호출
    if (mobileAppHeaerValues && sleeperId) {
      if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
        if (window.KblAndroid && window.KblAndroid.exitCallback) {
          window.KblAndroid.exitCallback();
        }
      } else {
        if (window.webkit
            && window.webkit.messageHandlers
            && window.webkit.messageHandlers.exitCallback
            && window.webkit.messageHandlers.exitCallback.postMessage) {
          window.webkit.messageHandlers.exitCallback.postMessage('');
        }
      }

    }
    history.push('/auth/login');
  }

  const clickNoButton = e => {
    removeAuthTokens();
    dispatch(setIsLogin(false));
    setIsSleeperDisplay(false)

    const mobileAppHeaerValues = getMobileAppHeaderData();
    // app 인 경우 웹뷰 종료 callback 호출
    if (mobileAppHeaerValues && sleeperId) {
      if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
        if (window.KblAndroid && window.KblAndroid.exitCallback) {
          window.KblAndroid.exitCallback();
        }
      } else {
        if (window.webkit
            && window.webkit.messageHandlers
            && window.webkit.messageHandlers.exitCallback
            && window.webkit.messageHandlers.exitCallback.postMessage) {
          window.webkit.messageHandlers.exitCallback.postMessage('');
        }
      }
    }
  }

  useEffect(_ => {
    if (isLogin) {
      history.push('/');
    }

    // 저장된 로그인 아이디 확인
    const savedIdValue = getLoginId();
    if (savedIdValue) {
      setId(savedIdValue);
      setIsSaveLoginId(true);
    }

    return function cleanup() {
      console.log('LoginContainer::componentWillUnmount');
    }
  }, [])

  useEffect(_ => {
    if (isLogin && window.opener && teamCode !== 'YH') {
      const accessToken = getAccessToken();
      window.opener.postMessage(accessToken, '*');
      window.close();
      return;
    }

    return function cleanup() {
      console.log('LoginContainer::componentWillUnmount');
    }
  }, [isLogin])

  useEffect(_ => {
    // app 휴면 인증 요청
    const _queryString = queryString.parse(location.search);
    if (!isEmptyObject(_queryString)) {
      if(_queryString.id) {
        setSleeperIdId(_queryString.id);
        setIsSleeperDisplay(true);
        removeAuthTokens();
        dispatch(setIsLogin(false));
        return;
      }
    }
  }, [])

  return (
    <>
      {
        terms ?
          <TermsComponent
            terms={terms}
            clickTermsLoginButton={clickTermsLoginButton}
          /> :
          <LoginComponent
            {...props}
            id={id}
            pwd={pwd}
            clickLoginButton={clickLoginButton}
            changeIdValue={changeIdValue}
            setId={setId}
            changePwdValue={changePwdValue}
            setPwd={setPwd}
            isSaveLoginId={isSaveLoginId}
            changeIsSaveLoginId={changeIsSaveLoginId}
          />
      }
      <SleeperAccountComponent
          isDisplay={isSleeperDisplay}
          clickOkButton={clickOkButton}
          clickNoButton={clickNoButton}
          sleeperId={sleeperId}
      />
      <AlertComponent
        clickCloseButton={alertPopupClose}
        isDisplay={isAlertPopupDisplay}
        content={alertMessage}
      />
    </>
  );
}

export default LoginContainer;